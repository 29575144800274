import React from "react";
import { navigate } from "gatsby";
import { isLoggedIn } from "../services/auth";
import { useEffect, useState } from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import { useDispatch, useSelector } from 'react-redux';
import { placeOrder, selectCheckoutState } from "../sagas/checkout/checkoutSlice";

const waitFiveSeconds = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, 5000);
  });
}

export default function Layout() {
  const dispatch = useDispatch();
  const { isPlacingOrderSuccess, isPlacingOrderFailed, PlaceOrderDto } = useSelector(selectCheckoutState);

  const handlePlaceOrder = async() => {
    const printfulOrder_payload = typeof window !== "undefined" && JSON.parse(localStorage.getItem("printfulOrder_payload") || "{}");

    if (printfulOrder_payload) {
      await waitFiveSeconds();

      dispatch({
        type: placeOrder.type,
        payload: printfulOrder_payload,
      });
    }
  }

  useEffect(() => {
    if (isPlacingOrderSuccess) {
      console.log('printful_order_placed', PlaceOrderDto);
      localStorage.removeItem("printfulOrder_payload");
    } else if (isPlacingOrderFailed) {
      console.log('printful_order_placed', 'Failed');
      handlePlaceOrder();
    }
  }, [isPlacingOrderSuccess, isPlacingOrderFailed, PlaceOrderDto])

  const [ordercost, setOrderCost] = useState({});
  const [orderCostItems, setOrderCostItems] = useState({});
  const [carouselImgArr, setCarouselImgArr] = React.useState([]);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }

    handlePlaceOrder();

    let cost =
      typeof window !== "undefined" &&
      JSON.parse(localStorage.getItem("order-cost") || "{}");
    setOrderCost(cost);

    let costItems =
      typeof window !== "undefined" &&
      JSON.parse(localStorage.getItem("order-cost-items") || "[]");
    setOrderCostItems(costItems);

    let orderImages =
      typeof window !== "undefined" &&
      JSON.parse(localStorage.getItem("order-carousel-images") || "[]");
    setCarouselImgArr(orderImages);

    localStorage.removeItem("getPaymentId_response");
    localStorage.removeItem("getOrderEst_response");
    localStorage.removeItem("selectedArray");
    localStorage.removeItem("final-products");
    localStorage.removeItem("order-cost");
    localStorage.removeItem("order-cost-items");
    localStorage.removeItem("order-carousel-images");
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getSize = (variant) => {
    if (!variant.color) return variant.size;
    return `${variant.color} Strap / ${variant.size}`;
  }

  return (
    <>
      <div className="container-main" id="page">
        <Header></Header>

        <main className="content-main">
          <div className="shipping-form">
            <div className="container">
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-lg-10 col-md-12">
                  <p style={{
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: 'unset',
                    color: '#595a5c',
                    fontFamily: 'Plus Jakarta Sans,sans-serif',
                    fontSize: '32px',
                    fontWeight: '600',
                  }}>
                    Thank you for your order
                  </p>
                </div>
                <div className="col-lg-10 col-md-12">
                  <div className="order-details">
                    <div className="paymment-options" style={{ padding: "24px 20px" }}>
                      <div className="review-order">
                        <h3>{`Review Order (${carouselImgArr?.length})`}</h3>
                        <ul>
                          {carouselImgArr &&
                            carouselImgArr.map((item, index) => (
                              <li key={index} style={{ justifyContent: "space-between" }}>
                                <div style={{ display: 'flex', width: 'calc( 47% + 90px )' }}>
                                  <div>
                                    <div style={{ width: '90px' }}>
                                      <img
                                        style={{borderRadius:5}}
                                        className="img-figure"
                                        src={`${item.image}?version=90`}
                                        alt="product-sml"
                                      />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '16px' }} className="ml-1">
                                    <h4>{item.model}</h4>
                                    <h4>Size: {getSize(item)}</h4>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                  <div className="price">{formatter.format(orderCostItems.find((cc) => cc.id === item.id)?.subtotal) ?? 0}</div>
                                </div>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="order-summary mt-3">
                    <h3>Order Summary</h3>
                    <div className="aside">
                      <ul>
                        <li>
                          <span className="left-l">Subtotal</span>{" "}
                          <span className="right-r">{ordercost?.subtotal ? formatter.format(ordercost?.subtotal) : ''}</span>
                        </li>
                        <li>
                          <span className="left-l">Shipping</span>{" "}
                          <span className="right-r">{ordercost?.shipping ? formatter.format(ordercost?.shipping) : ''}</span>
                        </li>
                        <li>
                          <span className="left-l">Sales Tax</span>{" "}
                          <span className="right-r">{ordercost?.sales_tax ? formatter.format(ordercost?.sales_tax) : ''}</span>
                        </li>
                        <li>
                          <span className="left-l">Merchant Fee</span>{" "}
                          <span className="right-r">{ordercost?.merchant_fees ? formatter.format(ordercost?.merchant_fees) : ''}</span>
                        </li>
                        <li>
                          <span className="left-l">Processing Fee</span>{" "}
                          <span className="right-r">{ordercost?.prcessing_fees ? formatter.format(ordercost?.prcessing_fees) : ''}</span>
                        </li>
                      </ul>
                      <div className="total">
                        <span className="left-l">Grand Total</span>{" "}
                        <span className="right-r">{ordercost?.grand_total ? formatter.format(ordercost?.grand_total) : ''}</span>
                      </div>
                    </div>
                    <div className="gray-box">
                      Our content review team usually accepts orders between 9am-6pm PST Monday-Saturday. Once your order is accepted, you'll receive a confirmation from us.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer1></Footer1>
      </div>
    </>
  );
}
